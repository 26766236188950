import HomeDemo4Container from '../template/HomeDemo4'
import Layout from "../layouts"
const HomeDemo4 = () => {

  return (
    <Layout Title="HomeDemo4">
      <HomeDemo4Container />
    </Layout>
  );
}

export default HomeDemo4;