import SectionHeading from "../SectionHeading";
import NewsContent from "./NewsContent";
// import Coin1 from "../../assets/img/coin/coin-phase-1.svg";
// import Coin2 from "../../assets/img/coin/coin-phase-2.svg";
// import Coin3 from "../../assets/img/coin/coin-phase-3.svg";
// import Coin4 from "../../assets/img/coin/coin-phase-4.svg";
// import Coin5 from "../../assets/img/coin/coin-phase-5.svg";
// updated
// import Coin1 from "../../assets/img/coin/coinPhase1.png";
// import Coin2 from "../../assets/img/coin/coinPhase2.png";
// import Coin3 from "../../assets/img/coin/coinPhase3.png";
// import Coin4 from "../../assets/img/coin/coinPhase4.png";
// import Coin5 from "../../assets/img/coin/coinPhase5.png";
// import LinkBg from "../../assets/img/coin/Link.png";
import RoadMap from "../../assets/img/coin/coinRm.svg";

const OurRoadmap = ({ data }) => {
  return (
    <section className="roadmap section-padding-100-0" id="roadmap">
      {/* <div style={{ display: "flex", justifyContent: "center" }}>
        <a
          href="#"
          className="btn more-btn "
          style={{ backgroundImage: `url(${LinkBg})` }}
        >
          Our RoadMap{" "}
        </a>
      </div> */}

      <SectionHeading
        ClassTitle="gradient-text"
        title={"Roadmap"}
        btnColor="yellow"
        text={
          "Charting the Course: Roadmap to Unraveling Satoshi's Digital Odyssey"
        }
      />

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="timeline">
              <div className="date-title">
                <img src={RoadMap} />
                {/* <span style={{ background: "#FFB300" }}>
                  {data.roadmap.title}
                </span> */}
              </div>

              {/* <div className="row">
                <NewsContent
                  ClassItem="col-sm-12 col-md-6 news-item coinContent"
                  title=""
                  ClassNewsMediaBx="news-media"
                  ClassNewsMediaLink="colorbox cboxElement"
                  img=""
                  largeImg={Coin1}
                  isCoin

                  // text={item.text}
                />

                <NewsContent
                  ClassItem="col-sm-12 col-md-6 news-item right coinContent"
                  title=""
                  ClassNewsMediaBx="news-media gallery"
                  ClassNewsMediaLink="colorbox cboxElement"
                  largeImg={Coin2}
                  img=""
                  addCboxElementLink={true}
                  isCoin
                  // text={item.text}
                />
                <NewsContent
                  ClassItem="col-sm-12 col-md-6 news-item coinContent"
                  title=""
                  ClassNewsMediaBx="news-media video"
                  ClassNewsMediaLink="colorbox-video cboxElement"
                  largeImg={Coin3}
                  // img={item.img}
                  img=""
                  isCoin

                  // text={item.text}
                />
                <NewsContent
                  ClassItem="col-sm-12 col-md-6 news-item right coinContent"
                  title=""
                  ClassNewsMediaBx="news-media gallery"
                  ClassNewsMediaLink="colorbox cboxElement"
                  largeImg={Coin4}
                  // img={item.img}
                  img=""
                  addCboxElementLink={true}
                  isCoin
                  // text={item.text}
                />
                <NewsContent
                  ClassItem="col-sm-12 col-md-6 news-item left coinContent"
                  title=""
                  ClassNewsMediaBx="news-media gallery"
                  ClassNewsMediaLink="colorbox cboxElement"
                  largeImg={Coin5}
                  // img={item.img}
                  img=""
                  addCboxElementLink={true}
                  isCoin
                  // text={item.text}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurRoadmap;
