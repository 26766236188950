import React from "react";
import styled from "styled-components";
import Logo from "../../../assets/img/fbi/logo.svg";
import LinkBg from "../../../assets/img/coin/Link.png";
const Container = styled.div`
  max-width: 1125px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media screen and (max-width: 990px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const LogoContainer = styled.div`
  flex: 0 0 35%; /* 50% width for desktop */
  padding: 20px;
`;

const ContentContainer = styled.div`
  flex: 0 0 65%; /* 50% width for desktop */
  padding: 20px;
  @media screen and (max-width: 990px) {
    text-align: center;
  }
`;

const Heading = styled.h1`
  font-size: 102px;
  font-family: mansalva;
  line-height: 100px;
  @media screen and (max-width: 600px) {
    font-size: 62px;
    line-height: 60px;
  }
`;

const SecondaryHeading = styled.h2`
  font-size: 42px;
  font-family: mansalva;
  @media screen and (max-width: 600px) {
    font-size: 32px;
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
`;

const Button = styled.button`
  font-size: 16px;
  padding: 10px 20px;
`;

const HeroHeader = () => {
  return (
    <Container>
      <LogoContainer>
        {/* Your logo or image goes here */}
        <img src={Logo} alt="Logo" />
      </LogoContainer>
      <ContentContainer>
        <Heading>FBI</Heading>
        <SecondaryHeading>Finding Bitcoin Satoshi for Indexx</SecondaryHeading>
        <Paragraph>
          FBI invites you to join a captivating journey to discover the elusive
          creator of Bitcoin, Satoshi Nakamoto. Dive into the world of
          cryptocurrency mysteries on Indexx and unlock the secrets behind the
          legendary figure. Join us today and unravel the story behind Bitcoin's
          beginnings!
        </Paragraph>
        <a
          href="/fbi"
          className="btn more-btn mr-3"
          style={{ backgroundImage: `url(${LinkBg})` }}
        >
          FBI
        </a>
      </ContentContainer>
    </Container>
  );
};

export default HeroHeader;
