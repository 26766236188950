import AboutText from "../../assets/img/collection/about text.svg";
const AboutOurCollection = ({
  ClassItem,
  ClassBxImgUp,
  ClassBxImg,
  ClassBxText,
  data,
  AboutOurCollectionImg,
  addImgRings = false,
}) => {
  return (
    <section className={ClassItem}>
      <div className="container">
        <div className="row align-items-center">
          <div className={ClassBxImgUp}>
            <div className={ClassBxImg} data-aos="fade-up">
              {AboutOurCollectionImg ? (
                <img draggable="false" src={AboutOurCollectionImg} alt="" />
              ) : (
                <img draggable="false" src={data.img} alt="" />
              )}
              {addImgRings && (
                <img
                  draggable="false"
                  src="img/core-img/rings.png"
                  className="center-block img-responsive rings "
                  alt=""
                />
              )}
            </div>
          </div>
          <div className={ClassBxText}>
            <div className="who-we-contant" style={{ marginTop: "50px" }}>
              {/* <div className="dream-dots text-left" data-aos="fade-up">
                <span className="gradient-text ">{data.dreamDotsText}</span>
              </div> */}
              <img src={AboutText} alt="abut-img" />
              {/* <a className="btn more-btn mt-20" href="#">
                {data.btnText}
              </a> */}
              <a href="/">
                <div className="dream-dots text-left mt-20" data-aos="fade-up">
                  <span className="gradient-text ">{data.btnText}</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutOurCollection;
