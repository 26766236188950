import styled from "styled-components";

export const Container = styled.div`
  /* width: calc(100vw / (32 / 5));
  height: calc(100vh / 6);
  font-size: 12rem; */
  border-radius: 10px;
  margin: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.1s ease-in-out;

  opacity: ${({ isVisible }) => (isVisible ? "1" : "0")};

  pointer-events: ${({ isVisible, disabled }) => {
    if (disabled || !isVisible) return "none";
    return "all";
  }};

  color: ${({ isShowingFrontFace, theme }) =>
    isShowingFrontFace ? "#111111" : "#e6e6e6"};

  background: ${({ isShowingFrontFace, theme }) =>
    isShowingFrontFace ? "#e6e6e6" + " !important" : "#111111"};

  &:hover {
    background: #00bbcc;
  }

  /* transform-style: preserve-3d;
  perspective: 1000px;
  &:active {
    transform: rotateY(180deg);
  } */
`;
