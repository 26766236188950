import OrdinalText from "../../../assets/img/ai-engine/ai-text.svg";
import OrdinalLogo from "../../../assets/img/ai-engine/logo.png";
import HeroHeader from "../../../components/FBI/HeroHeader";

const WelcomeArea = ({ data }) => {
  return (
    <section class="hero-section de-345 section-padding" id="home">
      {/* Hero Content */}
      <div className="hero-section-content">
        <div className="container ordinal-text">
          <div>
            <h2
              style={{
                fontFamily: "mansalva",
                fontSize: "58px",
                textAlign: "center",
                margin: "200px auto 50px auto",
              }}
            >
              Coming soon on June 30
            </h2>
          </div>
          <div className="row align-items-center">
            {/* Welcome Content */}

            <HeroHeader />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WelcomeArea;
