import { useState, useMemo, useEffect } from "react";

export default (startTimerOnRender = false, initialTime = 0) => {
  const [isTimerRunning, setIsTimerRunning] = useState(startTimerOnRender);
  const [milliseconds, setMilliseconds] = useState(initialTime);

  const onStartTimer = () => {
    setIsTimerRunning(true);
  };

  const onPauseTimer = () => {
    setIsTimerRunning(false);
  };

  const onStopTimer = () => {
    setIsTimerRunning(false);
    setMilliseconds(0);
  };

  useEffect(() => {
    let timer = null;

    if (isTimerRunning) {
      timer = setInterval(() => {
        setMilliseconds(milliseconds + 100);
      }, 100);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [isTimerRunning, milliseconds]);

  const { seconds, minutes, hours } = useMemo(() => {
    const secondsValue = milliseconds / 1000;
    const minutesValue = secondsValue / 60;
    const hoursValue = minutesValue / 60;

    return {
      seconds: secondsValue - Math.floor(minutesValue) * 60,
      minutes: minutesValue - Math.floor(hoursValue) * 60,
      hours: hoursValue,
    };
  }, [milliseconds]);

  const onGetNumberText = (number) => {
    const newNumber = Math.floor(number);
    return newNumber < 10 ? `0${newNumber}` : `${newNumber}`;
  };

  const secondsText = useMemo(() => onGetNumberText(seconds), [seconds]);
  const minutesText = useMemo(() => onGetNumberText(minutes), [minutes]);
  const hoursText = useMemo(() => onGetNumberText(hours), [hours]);

  return {
    milliseconds,
    seconds,
    minutes,
    hours,
    secondsText,
    minutesText,
    hoursText,
    onStartTimer,
    onStopTimer,
    onPauseTimer,
  };
};
