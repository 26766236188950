const NewsContent = ({
  ClassItem,
  num,
  day,
  title,
  ClassNewsMediaBx,
  ClassNewsMediaLink,
  img,
  largeImg,
  addCboxElementLink = false,
  text,
  isCoin,
}) => (
  <div className={ClassItem}>
    <div className="news-content" style={{ background: isCoin ? "none" : "" }}>
      {/* <div className="date">
                <p>{num}</p>
                <small>{day}</small>
            </div> */}
      <img src={largeImg} />
      <h2 className="news-title">{title}</h2>
      <div className={ClassNewsMediaBx}>
        <a className={ClassNewsMediaLink} href="#">
          <img className="img-responsive" src={img} alt="" />
        </a>
        {addCboxElementLink && <a className="colorbox cboxElement" href="#" />}
      </div>
      <p>{text}</p>
    </div>
  </div>
);

export default NewsContent;
