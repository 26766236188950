import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { PrimaryAction, SecondaryAction } from "./styles";
// import AlertModal from "../../../components/AlertModal";
import useClearGameState from "../useClearGameState";
import useCheckGameEnd from "../useCheckGameEnd";
import GameContext from "../GameContext";

const GameWinMessage = () => {
  const { isShowingWinModal, setIsShowingWinModal } = useContext(GameContext);

  const onClearGameState = useClearGameState();
  const history = useHistory();
  useCheckGameEnd();

  const onGoBack = () => {
    history.goBack();
  };

  const onPlayAgain = () => {
    setIsShowingWinModal(false);
    onClearGameState();
  };

  return (
    <h1>You win</h1>
    // <AlertModal
    //   isShowing={isShowingWinModal}
    //   onCloseModal={onPlayAgain}
    //   title="Congratulations!"
    //   message="You proved that your memory is powerful"
    // >
    //   <SecondaryAction onClick={onGoBack}>Go Back</SecondaryAction>
    //   <PrimaryAction onClick={onPlayAgain}>Play Again</PrimaryAction>
    // </AlertModal>
  );
};

export default GameWinMessage;
