import { useEffect, useState } from "react";
import data from "../../data/static.json";

import { Addshrink, moveSmooth } from "../../utils/";
// import twitter from "../../assets/img/icons/twitter.svg";
import twitter from "../../assets/img/icons/X white.svg";
// import twitter from "../../assets/img/icons/twitter new logo white 1.svg";
import telegram from "../../assets/img/icons/telegram.svg";
import "./header.css";

import Preloader from "../../components/Preloader";

import { NavLink, useLocation } from "react-router-dom";

import { useMediaQuery } from "react-responsive";
const Header = ({ Title }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    Addshrink();
  }, []);

  useEffect(() => {
    moveSmooth();
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  const isMob = useMediaQuery({
    query: "(max-width: 900px)",
  });
  return (
    <>
      <Preloader Title={Title} />
      <nav
        className="navbar navbar-expand-lg navbar-white fixed-top responsive-nav-bar"
        id="banner"
        style={{
          width: "100%",
          margin: "auto",
          marginTop: "70px",
        }}
      >
        <div className="container-fluid">
          {/* Brand */}

          <a
            className="navbar-brand"
            href="/"
            style={{ maxWidth: isMob ? "80%" : "100%", marginRight: "auto" }}
          >
            <span>
              <img
                className="hoverImg"
                draggable="false"
                src="img/core-img/logo.svg"
                alt="logo"
              />
            </span>{" "}
          </a>
          {/* Toggler/collapsibe Button */}
          <button
            onClick={() => {
              setIsVisible(!isVisible);
            }}
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#collapsibleNavbar"
          >
            <span className="navbar-toggler-icon" />
          </button>

          {/* Navbar links */}
          <div
            className={
              isVisible
                ? "navbar-collapse custom-collapse"
                : "collapse navbar-collapse"
            }
            id="collapsibleNavbar"
          >
            <ul className="navbar-nav ml-auto">
              {/* <li className="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Home
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  {data[0]?.header?.dataDown?.map((item, key) => (
                    <NavLink className="dropdown-item" key={key} to={item.path}>
                      {item.title}
                    </NavLink>
                  ))}
                </div>
              </li> */}

              {data[0]?.header?.MenuInfo?.map((item, key) => (
                <li className="nav-item" key={key}>
                  <NavLink
                    className="nav-link"
                    to={item.path}
                    style={{
                      color: pathname === item.path ? "#FA8D01" : "white",
                    }}
                  >
                    {item.nameLink}
                  </NavLink>
                </li>
              ))}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <a
                  href="https://twitter.com/whoisbtcsatoshi"
                  style={{ marginLeft: "10px" }}
                  target="_blank"
                >
                  <img src={twitter} alt="Twitter" width="25px" />
                </a>
                <a
                  href="https://t.me/Indexx_ai"
                  style={{ marginLeft: "10px" }}
                  target="_blank"
                >
                  <img src={telegram} alt="Twitter" width="20px" />
                </a>
              </div>

              {/* <li className="lh-55px">
                <a href="#" className="btn login-btn ml-50">
                  Connect Wallet
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
