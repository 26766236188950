import data from "../../data/static.json";
import { NavLink } from "react-router-dom";
import footerImg from "../../assets/img/wibs for footer.png";
const ContactInfo = ({ Class, ClassBx, children, title }) => (
  <div className={Class}>
    <div className="contact_info_area d-sm-flex justify-content-between">
      <div className={ClassBx}>
        <h5>{title}</h5>
        {children}
      </div>
    </div>
  </div>
);

const Footer = () => {
  return (
    <footer>
      <div
        className="container text-white"
        style={{ maxWidth: "800px", margin: "auto" }}
      >
        {/* First Row */}
        <div className="row">
          <div className="col text-center mb-5">
            <img src={footerImg} alt="Image" />
          </div>
        </div>

        {/* Second Row */}
        <div className="row custom-footer-class">
          <div className="col-sm-12 col-md-12 text-center  mb-5">
            <ul className="list-unstyled footer-links  mb-4">
              <li>
                <NavLink className="footer-links-icons" to="/about-us">
                  About
                </NavLink>
              </li>
              {/* <li>
                <NavLink className="footer-links-icons" to="#teamAreaSlide">
                  Team
                </NavLink>
              </li> */}
              <li>
                <NavLink className="footer-links-icons" to="/roadmap">
                  Roadmap
                </NavLink>
              </li>
              {/* <li>
                <NavLink className="footer-links-icons" to="">
                  Whitepaper
                </NavLink>
              </li> */}
              <li>
                <NavLink className="footer-links-icons" to="/contact-us">
                  Contact Us
                </NavLink>
              </li>
              {/* <li>
                <NavLink className="footer-links-icons" to="">
                  How to Buy?
                </NavLink>
              </li> */}
            </ul>
          </div>
        </div>

        {/* Third Row */}
        <div className="row">
          <div className="col text-center  mb-3">
            <h2 className=" mb-4" style={{ fontWeight: 400 }}>
              Contact Us
            </h2>
            <ul className="list-unstyled contact-links-footer">
              <li className="mb-3">Email: support@indexx.ai</li>
              <li className=" mb-3">
                Twitter:{" "}
                <a
                  href="https://twitter.com/Indexx_ai"
                  className="footer-links-icons"
                >
                  {" "}
                  https://twitter.com/Indexx_ai
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
