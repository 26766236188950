import OrdinalText from "../../../assets/img/ordinal/oridnaltext.svg";
import OrdinalLogo from "../../../assets/img/ordinal/Ordinal.svg";

const WelcomeArea = ({ data }) => {
  return (
    <section class="hero-section de-3 section-padding" id="home">
      {/* Hero Content */}
      <div className="hero-section-content">
        <div className="container ">
          <div className="row align-items-center">
            {/* Welcome Content */}
            <div className="col-12 col-lg-5 col-md-5 col-sm-12">
              <div className="ordinal-text">
                <img src={OrdinalText} alt="" />
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-12"></div>
            <div className="col-lg-5 col-md-5 col-sm-12 coin-img">
              <img src={OrdinalLogo} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WelcomeArea;
