import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";

const POLLING_INTERVAL = 12000;
const supportedChainIds = [11155111]; // 11155111 is the chain ID for Sepolia testnet

export const injected = new InjectedConnector({ supportedChainIds });

export const coinbaseWallet = new WalletLinkConnector({
  url: `https://sepolia.infura.io/v3/847e89cb852c43048f143456f82c32ec`, // Replace with your Infura project ID
  appName: "WhoIsBitcoinSatoshi",
  supportedChainIds,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
});

export const walletconnect = new WalletConnectConnector({
  rpc: {
    11155111: `https://sepolia.infura.io/v3/847e89cb852c43048f143456f82c32ec`, // Replace with your Infura project ID
  },
  appName: "WhoIsBitcoinSatoshi",
  supportedChainIds,
  pollingInterval: POLLING_INTERVAL,
});
