import React from "react";
import styled from "styled-components";

const SecondaryHeading = styled.h3`
  font-size: 74px;
  font-family: mansalva;
  @media screen and (max-width: 600px) {
    font-size: 32px;
  }
`;

const Paragraph = styled.p`
  font-size: 15px;
`;

const MostWanted = () => {
  return (
    <div style={{ maxWidth: "1150px", margin: "50px auto", padding: "20px" }}>
      <SecondaryHeading>Most wanted suspects in crypto</SecondaryHeading>
      <Paragraph>
        These are the group of elusive individuals, rumored to possess the power
        to make millions disappear with the click of a mouse. Their exploits
        include hacking, scamming, and evading authorities in a digital game of
        cat and mouse. Wanted posters adorn the virtual walls of the blockchain,
        warning users to stay vigilant lest they fall victim to these modern-day
        bandits of the digital frontier.
      </Paragraph>
    </div>
  );
};

export default MostWanted;
