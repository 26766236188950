import SectionHeading from "../SectionHeading";
import collection1 from "../../assets/img/collection/1.png.svg";
import collection2 from "../../assets/img/collection/2.png.svg";
import collection3 from "../../assets/img/collection/3.png.svg";
import collection4 from "../../assets/img/collection/4.png.svg";

const FindSatoshi = ({ data }) => {
  return (
    <section
      className="hero-section-home-leftt"
      style={{ maxWidth: "1980px", marginLeft: "auto", marginRight: "auto" }}
    >
      <div className="container my-container" style={{ maxWidth: "1100px" }}>
        <div className="row justify-content-center">
          <div className="col">
            <SectionHeading
              isFirstHead
              title={"Find Out Who is Bitcoin Satoshi"}
            />
          </div>
        </div>
        <div className="row justify-content-center mt-4">
          <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-3">
            <img
              // src="/img/collection/1.svg"
              src={collection4}
              alt="Image 1"
              className="img-fluid rounded"
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-3">
            <img
              // src="/img/collection/2.svg"
              src={collection1}
              alt="Image 2"
              className="img-fluid rounded"
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-3">
            <img
              // src="/img/collection/3.svg"
              src={collection3}
              alt="Image 3"
              className="img-fluid rounded"
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-3">
            <img
              // src="/img/collection/4.svg"
              src={collection2}
              alt="Image 4"
              className="img-fluid rounded"
              style={{ width: "100%" }}
            />
          </div>
        </div>

        <p style={{ textAlign: "center", marginTop: "30px" }}>Coming Soon</p>
      </div>
    </section>
  );
};

export default FindSatoshi;
