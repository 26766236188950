import data from "../../data/static.json";
import Breadcumb from "../../components/Breadcumb";
import DiscoverOurNewCollectionOrdianl from "../../components/DiscoverOurNewCollection/Ordinal";
import DiscoverOurNewCollectionNFT from "../../components/DiscoverOurNewCollection/NFT";

const CollectionContainer = () => {
  return (
    <>
      <Breadcumb
        title="Our Collection"
        addOtherTitle="COLLECTION"
        text="Home"
      />
      <DiscoverOurNewCollectionOrdianl
        ClassItem="section-padding-100"
        data={data[0].DiscoverOurNewCollection}
      />
      <DiscoverOurNewCollectionNFT
        ClassItem="section-padding-100"
        data={data[0].DiscoverOurNewCollection}
      />
    </>
  );
};

export default CollectionContainer;
