import SectionHeading from "../SectionHeading";

import one from "../../assets/img/nft/111.png";
import two from "../../assets/img/nft/112.png";
import three from "../../assets/img/nft/113.png";
import four from "../../assets/img/nft/114.png";
import five from "../../assets/img/nft/115.png";
import six from "../../assets/img/nft/116.png";
import seven from "../../assets/img/nft/117.png";
import eight from "../../assets/img/nft/118.png";
import nine from "../../assets/img/nft/119.png";
import ten from "../../assets/img/nft/120.png";
import eleven from "../../assets/img/nft/121.png";
import twelle from "../../assets/img/nft/122.png";
const DiscoverOurNewCollection = ({
  ClassItem = "collectione section-padding-100",
  data,
}) => {
  const imgArr = [
    one,
    two,
    three,
    four,
    five,
    six,
    seven,
    eight,
    nine,
    ten,
    eleven,
    twelle,
  ];
  return (
    <section className={ClassItem} style={{ paddingBottom: "70px" }}>
      <div className="container-fluid">
        <div
          style={{
            maxWidth: "900px",
            width: "100%",
            padding: "20px 15px",
            margin: "auto",
          }}
        >
          <SectionHeading
            ClassTitle="gradient-t"
            isFontShort
            title="Vast Collection Of Creative Who is Bitcoin Satoshi NFTs"
            text="Each NFT is on the contract that satoshi himself approved of, but there is a twist. The NFTs are hidden by satoshi himself,  you must mint as soon as we release them  to find where they are hiding. Maybe they will tell us where satoshi nakamoto is hiding."
          />
        </div>

        <div className="row">
          {imgArr?.map((item, key) => (
            <div className="col-12 col-xl-2 col-lg-3 col-md-6" key={key}>
              <div className="single_collection">
                <div className="collection-img">
                  <img src={item} alt="" />
                </div>
                <div className="collection-info">
                  {item?.title?.trim().length ? (
                    <h5>{item.title}</h5>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "15px",
                      }}
                    >
                      <img
                        src="/img/collection/qm.svg"
                        alt=""
                        style={{ width: "20px" }}
                      />
                    </div>
                  )}

                  {/* <a className="btn more-btn" href="#">
                    {item.btnText}
                  </a> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default DiscoverOurNewCollection;
