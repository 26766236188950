import React from "react";
import Brands from "../../assets/img/coin/brands.svg";
import top from "../../assets/img/coin/top.svg";
import OurRoadmap from "../CoinRoadMap";
function AboutSatoshi({ data }) {
  return (
    <section
      className=""
      style={{
        maxWidth: "1980px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <div
        className="container "
        style={{ maxWidth: "865px", marginTop: "250px" }}
      >
        <div className="text-center mt-4">
          <h3
            className="coin-page-heading"
            style={{
              fontSize: "100px",
              fontFamily: "Mansalva, sans-serif",
              marginBottom: "40px",
            }}
          >
            Coin Story
          </h3>
          {/* Heading */}
          <h3
            className="coin-page-heading"
            style={{
              fontSize: "50px",
              fontFamily: "Mansalva, sans-serif",
              textAlign: "left",
            }}
          >
            About Who is Bitcoin Satoshi Coin
          </h3>
          {/* Subheading */}
          <p
            className="coin-page-para"
            style={{ fontSize: "15px", textAlign: "left" }}
          >
            Who Is Bitcoin Satoshi Coin (WIBS) is more than just a
            cryptocurrency – it's a digital enigma waiting to be unraveled. With
            a price of $0.00021 and a symbol of WIBS, this meme coin boasts a
            total issuance of 210 billion tokens. Built upon a decentralized
            framework, the project holds true to Satoshi's vision, with the
            creator stepping away to uphold the ethos of anonymity.
            <br />
            Utilizing the robust infrastructures of Bitcoin, Ethereum, and
            Solana blockchains, Who Is Bitcoin Satoshi Coin ensures reliability
            and scalability.
            <br />
            As the journey unfolds, Who Is Bitcoin Satoshi Coin aims to expand
            its reach. Join us in exploring the depths of the digital realm and
            uncovering the mystery behind Satoshi's legacy.
          </p>
        </div>

        {/* <div
          style={{
            background: "#FFB300",
            width: "80%",
            height: "100%",
            padding: "30px 50px",
            margin: "250px auto 00px",
          }}
        >
          <div
            style={{
              margin: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={top} alt="" />
          </div>

          <div>
            <p style={{ color: "black" }}>Email to Get Started</p>
            <input
              type="text"
              style={{ width: "100%", height: "50px", border: "none" }}
            />
          </div>

          <div>
            <button
              style={{
                width: "100%",
                background: "#2D2D2D",
                height: "50px",
                color: "white",
                border: "none",
                margin: "20px 0px",
              }}
            >
              Connect Wallet
            </button>
            <button
              style={{
                width: "100%",
                background: "#2D2D2D",
                height: "50px",
                color: "white",
                border: "none",
                margin: "0px 0px",
              }}
            >
              Register
            </button>
          </div>
        </div> */}

        <div style={{ marginTop: "200px" }}></div>
        <OurRoadmap data={data[0].OurRoadmap} />
        <div style={{ maxWidth: "865px", margin: "300px auto 0px" }}>
          <p
            style={{
              textAlign: "center",
              fontSize: "30px",
              color: "white",
              marginBottom: "50px",
            }}
          >
            AS FEATURED IN
          </p>
          <img src={Brands} />
        </div>
      </div>
    </section>
  );
}

export default AboutSatoshi;
