import React from "react";
import styled from "styled-components";
import crossIcon from "../../../assets/img/coin/cross.png";
const BackdropContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11111;
`;

const ModalContent = styled.div`
  background-color: #ffaf00;
  border-radius: 10px;
  padding: 20px;
  max-width: 400px;
  text-align: center;
  position: relative;
`;

const Logo = styled.img`
  max-width: 100px;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  color: black;
  font-size: 16px;
`;
const Button = styled.button`
  width: 100%;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 0;
  cursor: pointer;
  margin-top: 20px;
  &:hover: {
    background-color: #272727;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;
const Backdrop = ({ logoSrc, onCloseClick, onClick }) => {
  return (
    <BackdropContainer>
      <ModalContent>
        <span
          onClick={onCloseClick}
          style={{
            position: "absolute",
            top: 0,
            right: -50,
            cursor: "pointer",
          }}
        >
          <img src={crossIcon} style={{}} />
        </span>
        <Logo src={logoSrc} alt="Logo" />
        <Paragraph style={{ textAlign: "left" }}>
          You have successfully purchased the tokens. You can verify the
          transaction in the following ways: <br />
          1. Go to your metamask and click on activity tab.
          <br /> OR <br />
          2. Click on verify below to check the transaction hash on ether scan.
        </Paragraph>
        <ButtonContainer>
          <Button onClick={onClick}>Verify</Button>
        </ButtonContainer>
      </ModalContent>
    </BackdropContainer>
  );
};

export default Backdrop;
