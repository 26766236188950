import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useWeb3React } from '@web3-react/core';
import { injected, walletconnect, coinbaseWallet } from '../../../smartcontract/walletConnect.js';
import MetaMaskLogo from '../../../assets/img/wallet-images/metamask.png'
import WalletConnectLogo from '../../../assets/img/wallet-images/walletconnect.png';
import CoinbaseLogo from '../../../assets/img/wallet-images/coinbase.png';
import { ethers } from "ethers";
import { addTokenToMetaMask } from '../../../smartcontract/web3Service/web3Service.js';

const ConnectModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center; // This will center the modal content vertically
  z-index: 1000; /* Ensure the modal is above other content */
`;

const ModalContent = styled.div`
  background: #FFB300; /* Change the background color */
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  text-align: center;
  z-index: 1001;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  h3, p {
    color: black; /* Ensure the text color is black */
  }
`;

const Button = styled.button`
  margin-top: 10px;
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
`;

// Reusing the style of BuyNowButton from your existing setup
const ConnectButton = styled(Button)`
  background: black;
  color: #fff;
  border-radius: 5px;
  &:hover {
    background: #272727;
  }
`;

const Logo = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;


const InstallModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const InstallModalContent = styled.div`
  background: #FFB300;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  text-align: center;
  z-index: 1001;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  h3, p {
    color: black; /* Ensure the text color is black */
  }
`;


const WalletButtons = ({ setProvider }) => {
  const { activate, account, connector } = useWeb3React();
  const [showModal, setShowModal] = useState(false);
  const [showInstallModal, setShowInstallModal] = useState(false);
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    return () => {
      setIsMounted(false);  // Cleanup function to set the flag to false when component unmounts
    };
  }, []);

  const connectWallet = async (connector) => {
    try {
      if (connector === injected && !window.ethereum) {
        setShowInstallModal(true);
        return;
      }

      await activate(connector);
      if (window.ethereum) {
        const ethProvider = new ethers.providers.Web3Provider(window.ethereum);
        if (isMounted) {
          setProvider(ethProvider);
          if (connector === injected) {
            await addTokenToMetaMask(); // Call importToken after setting the provider if MetaMask
          } else {
            alert('To add WIBS token, please manually add the token using the contract address: 0x4505b2D7b71D7c0dDDB999dA22bdCB8Baf09b1C3');
          }
          setShowModal(false);
        }
      }
    } catch (error) {
      console.error("Error on connecting: ", error);
    }
  };

  return (
    <>
      <ConnectButton onClick={() => setShowModal(true)}>Connect Wallet</ConnectButton>
      {showModal && (
        <ConnectModal>
          <ModalContent>
            <h3>Select Wallet</h3>
            <ConnectButton onClick={() => connectWallet(injected)}>
              <Logo src={MetaMaskLogo} alt="MetaMask" /> MetaMask
            </ConnectButton>
            <ConnectButton onClick={() => connectWallet(walletconnect)}>
              <Logo src={WalletConnectLogo} alt="WalletConnect" /> WalletConnect
            </ConnectButton>
            <ConnectButton onClick={() => connectWallet(coinbaseWallet)}>
              <Logo src={CoinbaseLogo} alt="Coinbase Wallet" /> Coinbase Wallet
            </ConnectButton>
            <ConnectButton onClick={() => setShowModal(false)}>Cancel</ConnectButton>
          </ModalContent>
        </ConnectModal>
      )}
      {showInstallModal && (
        <InstallModal>
          <InstallModalContent>
            <h3>Install MetaMask</h3>
            <p>To connect with MetaMask, you need to install the MetaMask extension.</p>
            <ConnectButton onClick={() => window.open('https://metamask.io/download.html', '_blank')}>
              Install MetaMask
            </ConnectButton>
            <ConnectButton onClick={() => setShowInstallModal(false)}>Cancel</ConnectButton>
          </InstallModalContent>
        </InstallModal>
      )}
    </>
  );
};

export default WalletButtons;
