import React, { useState } from "react";
import { useTimer } from "../../../../hooks";
import GameWinMessage from "./GameWinMessage";
import GameControlBar from "./GameControlBar";
import GameContext from "./GameContext";
import { Container } from "./styles";
import GameCards from "./GameCards";

const Game = () => {
  const [iconList, setIconList] = useState([]);
  const [iconFoundList, setIconFoundList] = useState([]);
  const [firstSelectedCard, setFirstSelectedCard] = useState(-1);
  const [secondSelectedCard, setSecondSelectedCard] = useState(-1);
  const [isShowingWinModal, setIsShowingWinModal] = useState(false);
  const [isCheckingCards, setIsCheckingCards] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  //   EASY: 12,
  //   MEDIUM: 20,
  //   HARD: 28,
  //   VERY_HARD: 32,
  const difficulty = 20;

  const {
    hoursText,
    minutesText,
    secondsText,
    onStartTimer,
    onStopTimer,
    onPauseTimer,
  } = useTimer();

  return (
    <GameContext.Provider
      value={{
        difficulty,
        isPaused,
        setIsPaused,
        iconList,
        setIconList,
        iconFoundList,
        setIconFoundList,
        firstSelectedCard,
        setFirstSelectedCard,
        secondSelectedCard,
        setSecondSelectedCard,
        isCheckingCards,
        setIsCheckingCards,
        isShowingWinModal,
        setIsShowingWinModal,
        hoursText,
        minutesText,
        secondsText,
        onStartTimer,
        onStopTimer,
        onPauseTimer,
      }}
    >
      <Container>
        <GameCards />
        <GameControlBar />
        {/* <GameWinMessage /> */}
      </Container>
    </GameContext.Provider>
  );
};

export default Game;
