import AiEngineTemplate from "../template/AiEngine";
import Layout from "../layouts";
const AiEngine = () => {
  return (
    <Layout Title="Ai Engine">
      <AiEngineTemplate />
    </Layout>
  );
};

export default AiEngine;
