import React, { useState } from "react";
import data from "../../data/static.json";

import Breadcumb from "../../components/Breadcumb";
import AboutOurCollection from "../../components/AboutOurCollection";
import Subscribe from "../../components/Subscribe";
import AboutImg from "../../assets/img/collection/WIBS about graphic 1.png";
import RightBg from "../../assets/img/collection/left-bg-1.png";
import LeftBg from "../../assets/img/collection/right-bg-1.png";

import twitter from "../../assets/img/icons/X white.svg";
import telegram from "../../assets/img/icons/telegram.svg";
import TeamArea from "../../components/TeamArea";
import UnveilingSatoshi from "../../components/Unveiling";
import Modal from "../../components/Modal";

const AboutContainer = () => {
  const [show, setShow] = useState(false);
  const handleTwitter = () => {
    navigate("/abc");
  };
  return (
    <>
      <Breadcumb title="About us" text="Home" />
      <div
        style={{
          position: "relative",
          maxWidth: "1980px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div style={{ position: "absolute", left: 0, top: 300 }}>
          <img
            className="about-us-img"
            src={RightBg}
            alt=""
            style={{ width: "270px" }}
          />
        </div>

        <div style={{ position: "absolute", right: 0, bottom: -500 }}>
          <img
            className="about-us-img"
            src={LeftBg}
            alt=""
            style={{ width: "200px" }}
          />
        </div>
        <div style={{ marginTop: "100px" }}></div>
        <UnveilingSatoshi isAbout />
        <AboutOurCollection
          ClassItem="about-us-area section-padding-100"
          ClassBxImgUp="col-12 col-lg-6 offset-lg-0 col-md-12 "
          ClassBxImg="welcome-meter floating-anim"
          ClassBxText="col-12 col-lg-6 offset-lg-0 mt-s"
          data={data[0].AboutOurCollection}
          AboutOurCollectionImg={AboutImg}
        />
        <div className="container" style={{ marginTop: "100px" }}>
          <div className="row text-center d-flex justify-content-center">
            <div className="col-12 col-lg-4 col-md-6 ">
              <div className="text-center">
                {/* Copywrite */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                  className="text-white social-img"
                  data-wow-delay="0.4s"
                >
                  Follow us at :
                  <a
                    href="https://twitter.com/whoisbtcsatoshi"
                    style={{ marginLeft: "10px" }}
                    target="_blank"
                  >
                    <img src={twitter} alt="Twitter" width="53px" />
                  </a>
                  <a
                    href="https://t.me/Indexx_ai"
                    style={{ marginLeft: "10px" }}
                    target="_blank"
                  >
                    <img src={telegram} alt="Twitter" width="40px" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: "300px" }}></div>
        <TeamArea data={data[0].TeamArea} setShow={setShow} />
      </div>

      <div style={{ marginBottom: "300px" }}></div>
      <Modal show={show} onClose={() => setShow(false)} />
    </>
  );
};

export default AboutContainer;
