import React, { useState } from "react";

import bg from "../../assets/img/coin/subscribeBg.png";
import LinkBg from "../../assets/img/coin/Link.png";
import axios from "axios";
const Subscribe = ({ data }) => {
  const [email, setEmail] = useState("");
  const [loading, setIsLoading] = useState(false);
  const onSubmit = () => {
    try {
      setIsLoading(true);
      axios
        .post("https://test.api.indexx.ai/api/v1/inex/basic/emailsubscribe", {
          email,
          website: "wibs",
        })
        .then((response) => {
          setEmail("");
          setIsLoading(false);
          console.log(response, "responnse");
        })
        .catch((error) => {
          setIsLoading(false);
        });
    } catch (err) {
      console.log(err, "err inn subscribe form");
      setIsLoading(false);
    }
  };

  return (
    <section className="container" style={{ position: "relative" }}>
      <div className="subscribe section-padding-0-100">
        <div className="row">
          <div className="col-sm-12">
            <div
              className="subscribe-wrapper"
              style={{ backgroundImage: `url(${bg})` }}
            >
              <div className="col-lg-7">
                <div className="section-heading text-left">
                  <h2
                    data-aos="fade-up"
                    style={{ fontFamily: "Mansalva, sans-serif" }}
                  >
                    {data?.title}
                  </h2>
                  <p data-aos="fade-up">{data?.text}</p>
                </div>
                <div className="service-text">
                  <div className="subscribe-section">
                    <div className="input-wrapper">
                      <i className="fa fa-envelope" />
                      <input
                        type="email"
                        placeholder="Enter your Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <button onClick={onSubmit} className="btn more-btn">
                      {`${loading ? "Submitting" : "Subscribe"}`}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div style={{ marginTop: "150px" }}>
                  <img src={data?.img} className="ava" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
