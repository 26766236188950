import React from "react";
import styled from "styled-components";
import CommingSoon from "../../../assets/img/fbi/comingsoon.png";
import LeftBg from "../../../assets/img/fbi/leftBg.png";
import rewardChart from "../../../assets/img/fbi/rewardChart.svg";
import uploadPhoto from "../../../assets/img/fbi/uploadPhoto.png";
import rewardChart2 from "../../../assets/img/fbi/rewardChart2.svg";
import Game from "./Game";
const HeadingContainer = styled.section`
  max-width: 1000px;
  width: 100%;
  padding: 20px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Heading = styled.h3`
  text-align: center;
  font-family: mansalva;
  font-size: 68px;
`;
const Paragraph = styled.p`
  text-align: center;
  font-size: 15px;
`;
const Link = styled.a`
  color: #fa8d01;
  font-size: 15px;
  text-align: center;
  text-decoration: underline;
`;

const ImgContainer = styled.div`
  width: 80%;
  padding: 20px;
  margin: 50px auto;
`;
const RewardContainer = styled.section`
  padding: 20px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
`;

const UploadContainer = styled.section`
  max-width: 430px;
  width: 100%;
  margin: 50px auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0px;
  gap: 10px;
`;
const FindingSatoshiMatchingGame = () => {
  return (
    <div>
      <HeadingContainer>
        <Heading>Finding Satoshi Matching Game</Heading>
        <Paragraph>
          "Finding Satoshi" is an engaging and fun matching game designed to
          test players' memory skills. Players are tasked with flipping cards to
          uncover matching pairs of cards within a limited number of attempts.
        </Paragraph>
        <Link href="#">See game mechanics</Link>
      </HeadingContainer>
      {/* <ImgContainer>
        <img src={CommingSoon} width="100%" />
      </ImgContainer> */}
      <Game />
      <RewardContainer>
        <img src={LeftBg} style={{ maxWidth: "500px", width: "100%" }} />
        <img src={rewardChart} />
        <div style={{ width: "100px" }}></div>
      </RewardContainer>
      <HeadingContainer>
        <Heading>Report a Wanted Suspect</Heading>
        <Paragraph>
          Join the hunt for justice in our interactive game, "Report a Wanted
          Suspect!" Upload a photo and earn rewards by matching it with wanted
          suspects from our database. It's crime-solving made fun!
        </Paragraph>
        <Link href="#">See game mechanics</Link>
      </HeadingContainer>
      <UploadContainer>
        <img src={uploadPhoto} />
        <ButtonContainer>
          <button
            style={{
              background: "none",
              border: "1px solid #FA8D01",
              color: "white",
              flex: 1,
              padding: "15px 15px",
              cursor: "pointer",
            }}
          >
            Claim Reward
          </button>
          <button
            style={{
              background: "#FA8D01",
              border: "none",
              flex: 1,
              padding: "15px 15px",
              cursor: "pointer",
            }}
          >
            Submit
          </button>
        </ButtonContainer>
      </UploadContainer>
      <HeadingContainer>
        <Heading>Reward chart</Heading>
        <Paragraph>
          Introducing our rewards table based on image proximity to the suspect!
          The closer the match, the greater the reward. Join the hunt for
          justice and earn rewards for pinpoint accuracy!
        </Paragraph>
      </HeadingContainer>
      <div
        style={{
          padding: "0px 20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img src={rewardChart2} />
      </div>
    </div>
  );
};

export default FindingSatoshiMatchingGame;
