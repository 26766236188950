import { useEffect } from "react";
import { addRemoveClassBody } from "../../utils";

import DiscoverOurNewCollection from "../../components/DiscoverOurNewCollection";
import HowItWorks from "../../components/HowItWorks";
import OurBrands from "../../components/OurBrands";
import OurRoadmap from "../../components/CoinRoadMap";
import FAQ from "../../components/FAQ";
import data from "../../data/static.json";
import WelcomeArea from "./WelcomeArea";
import TeamArea from "../../components/TeamArea";
import Subscribe from "../../components/CoinSubscribe";
import ContactArea from "../../components/ContactArea";
import AboutSatoshi from "../../components/AboutSatoshiCoin";
import Tokenomics from "../../components/Tokenomics";
import Text from "../../assets/img/coin/text-part.svg";
import LinkBg from "../../assets/img/coin/Link.png";
import { useMediaQuery } from "react-responsive";
import HeroHeader from "../../components/FBI/HeroHeader";
import leftBg from "../../assets/img/coin/leftBg.png";
import rightBg from "../../assets/img/coin/rightBg.png";
import rightBg2 from "../../assets/img/coin/rightBg2.png";

import fbiIcon from "../../assets/img/coin/FBI.svg";
import stakingIcon from "../../assets/img/coin/pig.svg";
import whitePaperIcon from "../../assets/img/coin/whitepaper.svg";
import auditIcon from "../../assets/img/coin/audit.svg";
import aboutIcon from "../../assets/img/coin/about.svg";
import "./style.css";
const HomeDemo2Container = () => {
  const isMob = useMediaQuery({
    query: "(max-width: 900px)",
  });
  useEffect(() => {
    addRemoveClassBody("darker");
  }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
          maxWidth: "850px",
          margin: "auto",
          marginTop: "150px",
          display: "flex",
          justifyContent: "space-evenly",
          padding: "20px",
          flexWrap: "wrap",
          textAlign: "center",
        }}
      >
        <div style={{ minWidth: "150px", cursor: "pointer" }}>
          <a href="/fbi">
            <img
              src={fbiIcon}
              style={{ height: "50px" }}
              className="hover-img"
            />
            <p>FBI</p>
          </a>
        </div>

        <div style={{ minWidth: "150px", cursor: "pointer" }}>
          <a href="https://smallpdf.com/file#s=b3cde823-96de-4a01-b35f-5fc7b06c72e1">
            <img
              src={whitePaperIcon}
              style={{ height: "50px" }}
              className="hover-img"
            />
            <p>Whitepaper</p>
          </a>
        </div>
        <div style={{ minWidth: "150px", cursor: "pointer" }}>
          <a href="https://smallpdf.com/file#s=94f05479-8ca5-4236-a44c-a977bd34230b">
            <img
              src={auditIcon}
              style={{ height: "50px" }}
              className="hover-img"
            />
            <p>Audit</p>
          </a>
        </div>
        <div style={{ minWidth: "150px", cursor: "pointer" }}>
          <a href="/about-us">
            <img
              src={aboutIcon}
              style={{ height: "50px" }}
              className="hover-img"
            />
            <p>About Coin</p>
          </a>
        </div>
      </div>
      <WelcomeArea data={data[0].HomeDemo2.WelcomeArea} />
      <div className="clearfix" />
      <div style={{ marginTop: "200px" }}></div>

      <div
        style={{
          maxWidth: "1980px",
          marginLeft: "auto",
          marginRight: "auto",
          position: "relative",
        }}
      >
        {!isMob && (
          <>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 0,

                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <img src={rightBg} style={{ width: "80%" }} />
            </div>
            <div
              style={{
                position: "absolute",
                left: 0,
                top: 1200,

                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <img src={leftBg} style={{ width: "80%" }} />
            </div>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 2800,

                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <img src={rightBg2} style={{ width: "60%" }} />
            </div>
          </>
        )}

        <HeroHeader />
        <div style={{ marginTop: "200px" }}></div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: isMob ? "0px auto" : "200px auto 60px auto",
          }}
        >
          <div className="col-12 col-lg-5 col-md-5 col-sm-12">
            <div className="welcome-content" style={{ padding: 0 }}>
              <img src={Text} alt="logo" />
              <p className="w-text">{data[0].HomeDemo2.WelcomeArea.text}</p>
              <div className="dream-btn-group coin-btn">
                <a
                  href="https://smallpdf.com/file#s=047294c5-a7e0-4718-8d59-bc9d1c0969f7"
                  className="btn more-btn mr-3"
                  style={{ backgroundImage: `url(${LinkBg})` }}
                >
                  {data[0].HomeDemo2.WelcomeArea.btnUp}
                </a>
                <a
                  href="#"
                  className="btn more-btn"
                  style={{ backgroundImage: `url(${LinkBg})` }}
                >
                  {data[0].HomeDemo2.WelcomeArea.btnDown}
                </a>
              </div>
            </div>
          </div>
        </div>

        <AboutSatoshi data={data} />

        <Tokenomics />

        <div style={{ marginTop: "250px" }}></div>

        <Subscribe data={data[0].Subscribe} />
        <div style={{ marginTop: "350px" }}></div>

        <ContactArea data={data[0].ContactArea} />
        <div style={{ marginTop: "250px" }}></div>
      </div>
    </>
  );
};

export default HomeDemo2Container;
