import { useEffect } from "react";
import { addRemoveClassBody } from "../../utils";

import DiscoverOurNewCollection from "../../components/DiscoverOurNewCollection";
import HowItWorks from "../../components/HowItWorks";
import OurBrands from "../../components/OurBrands";
import OurRoadmap from "../../components/OrdinalRoadMap";
import FAQ from "../../components/FAQ";
import data from "../../data/static.json";
import WelcomeArea from "./WelcomeArea";
import TeamArea from "../../components/TeamArea";
import Subscribe from "../../components/Subscribe";
import ContactArea from "../../components/ContactArea";
import OrdinalFindSatoshi from "../../components/OrdinalFindSatoshi";
import RightBg from "../../assets/img/collection/right-bg.png";
const HomeDemo2Container = () => {
  useEffect(() => {
    addRemoveClassBody("darker");
  }, []);

  return (
    <>
      <WelcomeArea data={data[0].HomeDemo2.WelcomeArea} />
      <div className="clearfix" />
      <section className="features section-padding-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="service-img-wrapper col-lg-6 col-md-9 col-sm-12 mt-s"></div>
          </div>
        </div>
      </section>
      <OrdinalFindSatoshi />

      <div
        style={{
          marginTop: "00px",
          position: "relative",
          maxWidth: "1980px",
          marginLeft: "auto",
          marginRight: "auto",
          fontFamily: "Mansalva, sans-serif",
        }}
      >
        <div style={{ position: "absolute", right: 0, top: 100 }}>
          <img className="ordinal-right-img" src={RightBg} alt="" />
        </div>
        <OurRoadmap data={data[0].OurRoadmap} />
        {/* <FAQ data={data[0].FAQ} /> */}

        <div style={{ marginTop: "250px" }}></div>

        <Subscribe data={data[0].Subscribe} />
      </div>

      {/* <TeamArea data={data[0].TeamArea} /> */}
      <div style={{ marginTop: "250px" }}></div>

      <ContactArea data={data[0].ContactArea} />
      <div style={{ marginTop: "250px" }}></div>
    </>
  );
};

export default HomeDemo2Container;
