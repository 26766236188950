import React from "react";

import LinkBg from "../../assets/img/coin/Link.png";
import leftImg from "../../assets/img/coin/tokenomics_left.svg";
import rightImg from "../../assets/img/coin/tokenomics_right.svg";
import styled from "styled-components";
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Gap between images */
  padding: 20px; /* Padding for the parent container */
`;

const Image = styled.img`
  max-width: 500px; /* Ensure images don't exceed their container */
  padding: 20px;
  width: 100%;
`;
const Heading = styled.h2`
  font-size: 112px;
  text-align: center;
  font-family: mansalva;
  @media screen and (max-width: 600px) {
    font-size: 62px;
    fontfamily: mansalva;
  }
`;
function Tokenomics() {
  return (
    <section
      className=""
      style={{
        maxWidth: "1980px",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "250px",
      }}
    >
      <div
        className="container "
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <Heading>Tokenomics</Heading>
        </div>
        <Container>
          <Image src={leftImg} alt="Image 1" />
          <Image src={rightImg} alt="Image 2" />
        </Container>
      </div>
    </section>
  );
}

export default Tokenomics;
