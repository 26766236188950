import React from "react";
import SectionHeading from "../../SectionHeading";
import collection1 from "../../../assets/img/fbi/pinksatoshi.png";

const BitcoinSatoshiOrdinal = () => {
  return (
    <div>
      <section
        className="hero-section-home-lefttt"
        style={{ maxWidth: "1980px", margin: "0px auto" }}
      >
        <div className="container my-container" style={{ maxWidth: "1100px" }}>
          <div className="row justify-content-center">
            <div className="col">
              {/* <h3
                style={{
                  fontFamily: "mansalva",
                  fontSize: "60px",
                  textAlign: "center",
                }}
              >
                Bitcoin Satoshi Ordinal
              </h3> */}
            </div>
          </div>
          <div className="row justify-content-center mt-4">
            <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-3">
              <img
                // src="/img/collection/1.svg"
                src={collection1}
                alt="Image 1"
                className="img-fluid rounded"
                style={{ width: "100%" }}
              />
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "mansalva",
                  fontSize: "20px",
                  marginTop: "-15px",
                }}
              >
                Satoshi Nakamoto
              </p>
            </div>
            <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-3">
              <img
                // src="/img/collection/2.svg"
                src={collection1}
                alt="Image 2"
                className="img-fluid rounded"
                style={{ width: "100%" }}
              />
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "mansalva",
                  fontSize: "20px",
                  marginTop: "-15px",
                }}
              >
                Wanted #2
              </p>
            </div>
            <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-3">
              <img
                // src="/img/collection/3.svg"
                src={collection1}
                alt="Image 3"
                className="img-fluid rounded"
                style={{ width: "100%" }}
              />
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "mansalva",
                  fontSize: "20px",
                  marginTop: "-15px",
                }}
              >
                Wanted #3
              </p>
            </div>
            <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-3">
              <img
                // src="/img/collection/4.svg"
                src={collection1}
                alt="Image 4"
                className="img-fluid rounded"
                style={{ width: "100%" }}
              />
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "mansalva",
                  fontSize: "20px",
                  marginTop: "-15px",
                }}
              >
                Wanted #4
              </p>
            </div>
          </div>
          {/* <div className="row justify-content-center mt-4">
            <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-3">
              <img
                // src="/img/collection/1.svg"
                src={collection1}
                alt="Image 1"
                className="img-fluid rounded"
                style={{ width: "100%" }}
              />
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "mansalva",
                  fontSize: "20px",
                  marginTop: "-15px",
                }}
              >
                Wanted #5
              </p>
            </div>
            <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-3">
              <img
                // src="/img/collection/2.svg"
                src={collection1}
                alt="Image 2"
                className="img-fluid rounded"
                style={{ width: "100%" }}
              />
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "mansalva",
                  fontSize: "20px",
                  marginTop: "-15px",
                }}
              >
                Wanted #6
              </p>
            </div>
            <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-3">
              <img
                // src="/img/collection/3.svg"
                src={collection1}
                alt="Image 3"
                className="img-fluid rounded"
                style={{ width: "100%" }}
              />
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "mansalva",
                  fontSize: "20px",
                  marginTop: "-15px",
                }}
              >
                Wanted #7
              </p>
            </div>
            <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-3">
              <img
                // src="/img/collection/4.svg"
                src={collection1}
                alt="Image 4"
                className="img-fluid rounded"
                style={{ width: "100%" }}
              />
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "mansalva",
                  fontSize: "20px",
                  marginTop: "-15px",
                }}
              >
                Wanted #8
              </p>
            </div>
          </div> */}
        </div>
      </section>
    </div>
  );
};

export default BitcoinSatoshiOrdinal;
