import CoinContainer from "../template/Coin";
import Layout from "../layouts";
const HomeDemo2 = () => {
  return (
    <Layout Title="Coin">
      <CoinContainer />
    </Layout>
  );
};

export default HomeDemo2;
