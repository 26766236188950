import SectionHeading from "../SectionHeading";

const DiscoverOurNewCollection = ({
  ClassItem = "collectione section-padding-100",
  data,
}) => {
  return (
    <section className={ClassItem} style={{ paddingBottom: "70px" }}>
      <div
        className="container-fluid"
        style={{ fontFamily: "Mansalva, sans-serif" }}
      >
        <SectionHeading
          ClassTitle="gradient-t"
          title={data.SectionHeading.title}
          otherTitle={data.SectionHeading.otherTitle}
          // text={data.SectionHeading.text}
        />
        <div className="row">
          {data?.list?.map((item, key) => (
            <div className="col-12 col-xl-2 col-lg-3 col-md-6" key={key}>
              <div className="single_collection">
                <div className="collection-img">
                  <img src={item.img} alt="" />
                </div>
                <div className="collection-info">
                  {item.title.trim().length ? (
                    <h5>{item.title}</h5>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "15px",
                      }}
                    >
                      <img
                        src="/img/collection/qm.svg"
                        alt=""
                        style={{ width: "20px" }}
                      />
                    </div>
                  )}

                  {/* <a className="btn more-btn" href="#">
                    {item.btnText}
                  </a> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default DiscoverOurNewCollection;
