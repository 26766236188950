import { useEffect } from "react";
import { addRemoveClassBody } from "../../utils";

import DiscoverOurNewCollection from "../../components/DiscoverOurNewCollection";
import HowItWorks from "../../components/HowItWorks";
import OurBrands from "../../components/OurBrands";
import OurRoadmap from "../../components/NFTRoadMap";
import FAQ from "../../components/FAQ";
import data from "../../data/static.json";
import WelcomeArea from "./WelcomeArea";
import TeamArea from "../../components/TeamArea";
import Subscribe from "../../components/Subscribe";
import ContactArea from "../../components/ContactArea";
import OrdinalFindSatoshi from "../../components/OrdinalFindSatoshi";
import RightBg from "../../assets/img/collection/right-bg.png";
import LeftBg from "../../assets/img/collection/left-bg.png";
import Right2Bg from "../../assets/img/collection/right-two.png";
import memeCoin from "../../assets/img/ai-engine/memeCoin.svg";
import ordinalCoin from "../../assets/img/ai-engine/ordinalCoin.svg";
import nftCoin from "../../assets/img/ai-engine/nftCoin.svg";
import MostWanted from "../../components/FBI/MostWanted";
import BitcoinSatoshiOrdinal from "../../components/FBI/BitcoinSatoshiOrdinal";
import BitcoinSatoshiNft from "../../components/FBI/BitcoinSatoshiNft";
import FindingSatoshiMatchingGame from "../../components/FBI/FindingSatoshiGame";
const FBITemplate = () => {
  useEffect(() => {
    addRemoveClassBody("darker");
  }, []);

  return (
    <>
      <WelcomeArea data={data[0].HomeDemo2.WelcomeArea} />
      <div className="clearfix" />
      <MostWanted />

      <BitcoinSatoshiOrdinal />
      <BitcoinSatoshiNft />
      <FindingSatoshiMatchingGame />

      <div style={{ marginTop: "250px" }}></div>
    </>
  );
};

export default FBITemplate;
