import React from "react";
import Logo from "../../../assets/img/coin/Coin-logo.svg";
import Text from "../../../assets/img/coin/text-part.svg";
import LinkBg from "../../../assets/img/coin/Link.png";
import top from "../../../assets/img/coin/top.svg";
import infoLogo from "../../../assets/img/coin/info.svg";
import { useMediaQuery } from "react-responsive";
import CoinForm from "../CoinForm/index";
import Backdrop from "../BackdropModal";
const WelcomeArea = ({ data }) => {
  const isMob = useMediaQuery({
    query: "(max-width: 990px)",
  });

  const [url, setURL] = React.useState("");

  const handleButtonClick = () => {
    const selectedUrl = url;
    window.open(selectedUrl, "_blank");
  };

  const handleCloseBtn = () => {
    setURL("");
  };
  const handleCallback = (Url) => {
    setURL(Url);
  };
  return (
    <>
      <section class="hero-section de-34 section-padding" id="home">
        {/* Hero Content */}
        <div className="hero-section-content">
          <div className="container ">
            <div>
              {" "}
              <div
                style={{
                  background: "#FFB300",
                  width: isMob ? "100%" : "80%",
                  height: "100%",
                  padding: "20px 20px",

                  transform: !isMob && "translate(60%, 40%)",
                }}
              >
                <CoinForm setParentCB={handleCallback} />
                {/* <div
                style={{
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={top} alt="" />
              </div>

              <div>
                <p
                  style={{
                    color: "black",
                    fontSize: "11px",
                    fontWeight: "bold",
                    margin: "20px 0px 10px 0",
                  }}
                >
                  Email to Get Started
                </p>
                <input
                  type="text"
                  style={{ width: "100%", height: "50px", border: "none" }}
                />
              </div>

              <div>
                <button
                  style={{
                    width: "100%",
                    background: "#2D2D2D",
                    height: "50px",
                    color: "white",
                    border: "none",
                    margin: "20px 0px",
                  }}
                >
                  Connect Wallet
                </button>
                <button
                  style={{
                    width: "100%",
                    background: "#2D2D2D",
                    height: "50px",
                    color: "white",
                    border: "none",
                    margin: "0px 0px",
                  }}
                >
                  Register
                </button>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {url ? (
        <Backdrop
          logoSrc={infoLogo}
          onCloseClick={handleCloseBtn}
          onClick={handleButtonClick}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default WelcomeArea;
