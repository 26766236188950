import { useEffect } from "react";
import { addRemoveClassBody } from "../../utils";

import DiscoverOurNewCollection from "../../components/DiscoverOurNewCollection";
import HowItWorks from "../../components/HowItWorks";
import OurBrands from "../../components/OurBrands";
import OurRoadmap from "../../components/NFTRoadMap";
import FAQ from "../../components/FAQ";
import data from "../../data/static.json";
import WelcomeArea from "./WelcomeArea";
import TeamArea from "../../components/TeamArea";
import Subscribe from "../../components/Subscribe";
import ContactArea from "../../components/ContactArea";
import OrdinalFindSatoshi from "../../components/OrdinalFindSatoshi";
import RightBg from "../../assets/img/collection/right-bg.png";
import LeftBg from "../../assets/img/collection/left-bg.png";
import Right2Bg from "../../assets/img/collection/right-two.png";
import memeCoin from "../../assets/img/ai-engine/memeCoin.svg";
import ordinalCoin from "../../assets/img/ai-engine/ordinalCoin.svg";
import nftCoin from "../../assets/img/ai-engine/nftCoin.svg";
const HomeDemo2Container = () => {
  useEffect(() => {
    addRemoveClassBody("darker");
  }, []);

  return (
    <>
      <WelcomeArea data={data[0].HomeDemo2.WelcomeArea} />
      <div className="clearfix" />

      <div
        style={{
          maxWidth: "1100px",
          width: "100%",
          padding: "20px",
          margin: "50px auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={memeCoin} />
        <a className="btn more-btn" style={{ color: "white" }}>
          Coming Soon
        </a>
      </div>

      <div
        style={{
          maxWidth: "1100px",
          width: "100%",
          padding: "20px",
          margin: "150px auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={ordinalCoin} />
        <a
          className="btn more-btn"
          style={{ color: "white", marginTop: "50px" }}
        >
          Coming Soon
        </a>
      </div>

      <div
        style={{
          maxWidth: "1100px",
          width: "100%",
          padding: "20px",
          margin: "150px auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={nftCoin} />
        <a
          className="btn more-btn"
          style={{ color: "white", marginTop: "50px" }}
        >
          Coming Soon
        </a>
      </div>
      {/* <section className="features section-padding-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="service-img-wrapper col-lg-6 col-md-9 col-sm-12 mt-s"></div>
          </div>
        </div>
      </section> */}

      {/* <OrdinalFindSatoshi /> */}

      {/* <TeamArea data={data[0].TeamArea} /> */}
      <div style={{ marginTop: "250px" }}></div>

      <ContactArea data={data[0].ContactArea} />
      <div style={{ marginTop: "250px" }}></div>
    </>
  );
};

export default HomeDemo2Container;
