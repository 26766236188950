import { useContext } from "react";
import { useArrayShuffle } from "../../../../hooks";
import GameContext from "./GameContext";
import IconList from "./IconList";

export default () => {
  const {
    setIconList,
    setIconFoundList,
    setIsPaused,
    setFirstSelectedCard,
    setSecondSelectedCard,
    difficulty,
    onStopTimer,
  } = useContext(GameContext);

  const onShuffleArray = useArrayShuffle();

  const onClearGameState = () => {
    setIconFoundList([]);
    setIsPaused(true);
    setFirstSelectedCard(-1);
    setSecondSelectedCard(-1);
    onStopTimer();

    const shuffledArray = onShuffleArray(IconList);
    let correctLengthArray = shuffledArray.slice(0, difficulty / 2);
    correctLengthArray = correctLengthArray.concat(correctLengthArray);
    const correctLengthSuffledArray = onShuffleArray(correctLengthArray);
    setIconList(correctLengthSuffledArray);
  };

  return onClearGameState;
};
