import HeaderTop from "./HeaderTop";
import Header from "./Header";
import Footer from "./Footer";
import { useEffect } from "react";

const Layout = ({ children, Title }) => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });
  }, []);

  return (
    <>
      <HeaderTop />
      <Header Title={Title} />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
