import { ethers } from 'ethers';
import contractABI from '../ABI.json'; // Import your contract ABI here

const contractAddress = '0x4505b2D7b71D7c0dDDB999dA22bdCB8Baf09b1C3';

export const getWeb3 = () => {
  return new Promise((resolve, reject) => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      provider.send("eth_requestAccounts", []) // Request account access
        .then(() => {
          return provider.send('wallet_switchEthereumChain', [{ chainId: '0xaa36a7' }]);
        })
        .then(() => {
          resolve(provider);
        })
        .catch((error) => {
          if (error.code === 4902) {
            reject(new Error('Sepolia testnet is not configured in MetaMask.'));
          } else {
            reject(new Error('User denied account access or network switch.'));
          }
        });
    } else {
      reject(new Error('Please install MetaMask!'));
    }
  });
};

export const buyTokensWithETH = async (provider, ethAmount) => {
  const signer = provider.getSigner();
  const contract = new ethers.Contract(contractAddress, contractABI, signer);
  try {

    return contract.buyWithETH({
      value: ethers.utils.parseEther(ethAmount)
    });
  } catch (error) {
    console.error("Error purchasing tokens with ETH:", error);
    alert(`Error: ${error.message}`);
    return error; // Re-throw the error to be handled by the caller
  }
};

export const buyTokensWithUSDT = async (provider, usdtAmount) => {
  const signer = provider.getSigner();
  const contract = new ethers.Contract(contractAddress, contractABI, signer);
  try {
    return contract.buyWithUSDT(ethers.utils.parseUnits(usdtAmount, 'mwei'));
  }
  catch (error) {
    console.error("Error purchasing tokens with USDT:", error);
    alert(`Error: ${error.message}`);
    return error; // Re-throw the error to be handled by the caller
  }
};

export const addTokenToMetaMask = async () => {
  try {
    // Check if MetaMask is installed
    if (!window.ethereum) throw new Error("MetaMask is not installed.");

    const wasAdded = await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: contractAddress,
          symbol: "WIBS",
          decimals: 18,
          image: 'https://indexx-exchange.s3.ap-northeast-1.amazonaws.com/WIBS.png', // Optional logo URL
        },
      },
    });

    if (wasAdded) {
      console.log('Token was successfully added to MetaMask!');
    } else {
      console.log('Token was not added to MetaMask.');
    }
  } catch (error) {
    console.error('Error adding token to MetaMask:', error);
  }
};