import data from "../../data/static.json";

import Breadcumb from "../../components/Breadcumb";
import OurRoadmap from "../../components/OurRoadmap";
import RightBg from "../../assets/img/collection/left-bg.png";

const RoadmapContainer = () => {
  return (
    <>
      <Breadcumb title="Our Roadmap" addOtherTitle="Roadmap" text="Home" />

      <div
        style={{
          position: "relative",
          maxWidth: "1980px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div
          className="roadmap-img"
          style={{ position: "absolute", left: 0, top: 0 }}
        >
          <img src={RightBg} alt="" style={{ width: "270px" }} />
        </div>
        <div style={{ marginTop: "800px" }}></div>

        <OurRoadmap data={data[0].OurRoadmap} />
      </div>

      <div style={{ marginTop: "200px" }}></div>
    </>
  );
};

export default RoadmapContainer;
