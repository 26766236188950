import HomeDemo1Container from "../template/HomeDemo1";
import Layout from "../layouts";
const HomeDemo1 = () => {
  return (
    <Layout Title="Home">
      <HomeDemo1Container />
    </Layout>
  );
};

export default HomeDemo1;
