import SectionHeading from "../SectionHeading";
import NewsContent from "./NewsContent";

const OurRoadmap = ({ data }) => {
  return (
    <section className="roadmap section-padding-100-0" id="roadmap">
      <SectionHeading
        ClassTitle="gradient-text"
        title={data.SectionHeading.title}
        text={
          "Charting the Course: Roadmap to Unraveling Satoshi's Digital Odyssey"
        }
      />

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="timeline">
              <div className="date-title">
                <span>{data.roadmap.title}</span>
              </div>

              <div className="row">
                <NewsContent
                  ClassItem="col-sm-12 col-md-6 news-item"
                  title=""
                  ClassNewsMediaBx="news-media"
                  ClassNewsMediaLink="colorbox cboxElement"
                  img="/img/core-img/or-1.svg"
                  largeImg="/img/core-img/or-phase-1.svg"

                  // text={item.text}
                />

                <NewsContent
                  ClassItem="col-sm-12 col-md-6 news-item right"
                  title=""
                  ClassNewsMediaBx="news-media gallery"
                  ClassNewsMediaLink="colorbox cboxElement"
                  largeImg="/img/core-img/or-phase-2.svg"
                  img="/img/core-img/or-2.svg"
                  addCboxElementLink={true}
                  // text={item.text}
                />
                <NewsContent
                  ClassItem="col-sm-12 col-md-6 news-item"
                  title=""
                  ClassNewsMediaBx="news-media video"
                  ClassNewsMediaLink="colorbox-video cboxElement"
                  largeImg="/img/core-img/or-phase-3.svg"
                  // img={item.img}
                  img="/img/core-img/or-3.svg"

                  // text={item.text}
                />
                <NewsContent
                  ClassItem="col-sm-12 col-md-6 news-item right"
                  title=""
                  ClassNewsMediaBx="news-media gallery"
                  ClassNewsMediaLink="colorbox cboxElement"
                  largeImg="/img/core-img/or-phase-4.svg"
                  // img={item.img}
                  img="/img/core-img/or-4.svg"
                  addCboxElementLink={true}
                  // text={item.text}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurRoadmap;
