import React from "react";
import Layout from "../layouts";
import FBITemplate from "../template/FBITemplate";
const FBI = () => {
  return (
    <Layout Title="FBI">
      <FBITemplate />
    </Layout>
  );
};

export default FBI;
