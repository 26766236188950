import Ordinal from "../template/Ordinal";
import Layout from "../layouts";
const HomeDemo2 = () => {
  return (
    <Layout Title="Ordinal">
      <Ordinal />
    </Layout>
  );
};

export default HomeDemo2;
