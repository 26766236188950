import ImageOne from "../../../../assets/img/fbi/1.1.png";
import ImageTwo from "../../../../assets/img/fbi/2.1.png";
import ImageThird from "../../../../assets/img/fbi/3.1.png";
import ImageFour from "../../../../assets/img/fbi/4.1.png";
import ImageFive from "../../../../assets/img/fbi/5.1.png";
import ImageSix from "../../../../assets/img/fbi/6.1.png";
import ImageSeven from "../../../../assets/img/fbi/7.1.png";
import ImageEight from "../../../../assets/img/fbi/8.1.png";
import ImageNine from "../../../../assets/img/fbi/9.1.png";
import ImageTen from "../../../../assets/img/fbi/10.1.png";

export default [
  {
    name: "one",
    img: ImageOne,
  },
  {
    name: "two",
    img: ImageTwo,
  },
  {
    name: "three",
    img: ImageThird,
  },
  {
    name: "four",
    img: ImageFour,
  },
  {
    name: "five",
    img: ImageFive,
  },
  {
    name: "six",
    img: ImageSix,
  },
  {
    name: "seven",
    img: ImageSeven,
  },
  {
    name: "eight",
    img: ImageEight,
  },
  {
    name: "nine",
    img: ImageNine,
  },
  {
    name: "ten",
    img: ImageTen,
  },
];
