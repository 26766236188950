import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { ethers } from 'ethers';
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Function to get the library for Web3ReactProvider
// function getLibrary(provider) {
//   const library = new Web3Provider(provider);
//   library.pollingInterval = 12000; // Adjust polling interval as needed
//   return library;
// }

function getLibrary(provider) {
  return new ethers.providers.Web3Provider(provider);
}

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();