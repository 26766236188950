import styled from "styled-components";
import Card from "../Card";

export const Container = styled.div`
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 16px 64px 16px;
`;

export const CardItem = styled(Card)`
  min-height: 100px;
  min-width: 100px;
  height: 20vh;
  font-size: 8rem;

  background: #5138c3;
  &:hover {
    background: #fa8d01;
  }
  flex: 1 0
    calc((100% / ${({ numOfCardsInEachLine }) => numOfCardsInEachLine}) - 16px);
`;
