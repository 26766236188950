import React, { memo } from "react";
import { Container } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Card = (props) => {
  const {
    children,
    onClick,
    isShowingFrontFace = false,
    isVisible = true,
    className,
    disabled = false,
  } = props;

  return (
    <Container
      isVisible={isVisible}
      className={className}
      isShowingFrontFace={isShowingFrontFace}
      onClick={onClick}
      disabled={false}
    >
      {isShowingFrontFace ? (
        children
      ) : (
        <FontAwesomeIcon icon="question-circle" />
      )}
    </Container>
  );
};

export default memo(Card);
