import React, { useState } from "react";
import SectionHeading from "../SectionHeading";
import twitter from "../../assets/img/icons/X white.svg";
import telegram from "../../assets/img/icons/telegram.svg";
import axios from "axios";
const ContactArea = ({
  ClassItem = "contact_us_area section-padding-100-0",
  data,
}) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, SetIsLoading] = useState(false);
  const onSubmit = (e) => {
    e.preventDefault();
    try {
      SetIsLoading(true);
      axios
        .post("https://test.api.indexx.ai/api/v1/inex/basic/emailToAdmin", {
          email,
          website: "wibs",
          message,
        })
        .then((response) => {
          setEmail("");
          setMessage("");
          SetIsLoading(false);
          console.log(response, "responnse");
        })
        .catch((error) => {
          SetIsLoading(false);
        });
    } catch (err) {
      SetIsLoading(false);
      console.log(err, "err inn subscribe form");
    }
  };

  return (
    <div
      className={ClassItem}
      style={{
        backgroundImage: "url(img/core-img/pattern1.png)",
        backgroundPosition: "center",
        backgroundSize: "100%",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionHeading
              ClassTitle="gradient-text blue"
              title={data.SectionHeading.title}
              otherTitle={data.SectionHeading.otherTitle}
              text={data.SectionHeading.text}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "-60px",
                marginBottom: "50px",
              }}
              className="text-white social-img"
            >
              <a
                href="https://twitter.com/whoisbtcsatoshi"
                style={{ marginLeft: "10px" }}
                target="_blank"
              >
                <img src={twitter} alt="Twitter" width="53px" />
              </a>
              <a
                href="https://t.me/Indexx_ai"
                style={{ marginLeft: "10px" }}
                target="_blank"
              >
                <img src={telegram} alt="Twitter" width="40px" />
              </a>
            </div>
          </div>
        </div>
        {/* Contact Form */}
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <div className="contact_form">
              <form>
                <div className="row">
                  <div className="col-12">
                    <div id="success_fail_info" />
                  </div>
                  {data?.list?.map((item, key) => (
                    <div className={item.ClassUp} key={key} data-aos="fade-up">
                      <div className="group" data-wow-delay="0.5s">
                        {item.addTextArea ? (
                          <textarea
                            name={item.nameInput}
                            id={item.nameInput}
                            required
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                          />
                        ) : (
                          <input
                            type="text"
                            name={item.nameInput}
                            id={item.nameInput}
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        )}
                        <span className="highlight" />
                        <span className="bar" />
                        <label>{item.title}</label>
                      </div>
                    </div>
                  ))}
                  <div className="col-12 text-center" data-aos="fade-up">
                    <button onClick={onSubmit} className="btn more-btn">
                      {`${loading ? "submitting..." : data.send}`}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactArea;
