import React from "react";
import "./style.css";
import closeWhite from "../../assets/img/cross_white.png";
import Logo from "../../assets/img/popupLogo.svg";
const Modal = ({ show = true, onClose }) => {
  return (
    show && (
      <div className="backdrops">
        <div className="modals">
          <img
            className="close-button"
            src={closeWhite}
            style={{ width: "36px" }}
            onClick={onClose}
          />
          <div className="header">
            <img src={Logo} alt="Logo" />
          </div>
          <div className="content">
            <p>
              Thank you for your interest. We prefer to remain anonymous. Join
              our community to help discover the real Satoshi Nakamoto.
            </p>
          </div>
          <div className="footer">
            <a className="btn more-btn" href="https://t.me/Indexx_ai">
              Join Telegram
            </a>
            <button className="btn more-btn" onClick={onClose}>
              OK
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default Modal;
