import React from "react";
import ReadMoreReact from "read-more-react";
import leftimage from "../../assets/img/bg-img/left.png";

const UnveilingSatoshi = ({ isAbout }) => {
  return (
    <section
      className={`${isAbout ? "" : "hero-section-home-left"}`}
      style={{ maxWidth: "1980px", marginLeft: "auto", marginRight: "auto" }}
    >
      <div className="container " style={{ maxWidth: "636px", margin: "auto" }}>
        <h2
          style={{
            fontSize: "78px",
            fontFamily: "Mansalva, sans-serif",
            textAlign: "center",
          }}
        >
          Mystical Story
        </h2>
        <div className="text-center">
          {/* Logo */}
          <img
            src="/img/hero-header/hero-img-5.svg"
            alt="Logo"
            className="img-fluid"
          />
        </div>

        <div
          className="text-center mt-4"
          // style={{ fontFamily: "Mansalva, sans-serif" }}
        >
          {/* Heading */}
          <h2 style={{ fontSize: "42px", fontFamily: "Mansalva, sans-serif" }}>
            Unveiling the Enigma of Satoshi
          </h2>
          {/* Subheading */}
          <h4 style={{ fontSize: "16px", fontFamily: "Mansalva, sans-serif" }}>
            A Digital Tale of Secrecy, Creativity, and the Enduring Mystery in
            the Blockchain Realm
          </h4>
        </div>

        <div className=" mt-4">
          {/* Paragraph */}
          <p>
            <ReadMoreReact
              className="text-white readMoreStyle"
              text="In the quiet recesses of the digital realm, where whispers of blockchain secrets linger, a mysterious figure known only as Satoshi ventured into the shadows. Fueled by an insatiable curiosity and an unwavering desire for anonymity, Satoshi sought to unveil the enigma of his own existence through a creation that transcended the boundaries of the virtual world.
With a clandestine call echoing through the corridors of code, Satoshi reached out to a gifted creator, his true identity veiled behind layers of encryption. In hushed tones, Satoshi entrusted the creator with a monumental task: to craft a testament to his existence, a saga of tokens and NFTs that would echo through the ages. But there was one condition - the creator must guard Satoshi's identity with unwavering secrecy, ensuring that the cryptic enigma of Satoshi would forever remain elusive.
As the creator delved into the task, Satoshi's vision began to materialize. A meme coin, symbolic of the whimsical nature of the digital world, emerged from the creator's adept hands. Ordinals, each one a unique expression of Satoshi's essence, took shape in the form of digital art, destined to be cherished by those who sought to unravel the mystery.
Satoshi, ever vigilant in his quest for anonymity, extended his reach to the digital realm's gatekeepers. He approached Indexx.ai exchange, a sanctuary nestled in the heart of the blockchain landscape. Entrusting them with the guardianship of his creation, Satoshi implored Indexx.ai to be the exclusive host and provider of the Who is Bitcoin Satoshi meme tokens.
To further shroud his identity, Satoshi insisted on the creation of a mask – an emblem that would forever be associated with the elusive enigma. A mask to be worn by the tokens, ordinals, and the very essence of Who is Bitcoin Satoshi. A symbol of the faceless genius orchestrating the digital symphony.
Indexx.ai, now the sole conduit to the elusive tokens, stood as the gateway between the seeker and the sought. The exchange became the epicenter of intrigue, where the community gathered to trade, collect, and immerse themselves in the mysterious allure of Who is Bitcoin Satoshi.
The story unfolded, a digital tapestry woven with threads of secrecy, creativity, and the unquenchable thirst for discovery. As the meme tokens, ordinals, and NFTs found their way into the hands of those who dared to venture into the realm of the unknown, Satoshi's legacy endured, forever veiled in the mystique of the mask, leaving the question echoing through the digital corridors: Who is Bitcoin Satoshi, and will the mask ever be lifted?
"
              min={700}
              ideal={700}
              max={10000000000}
              readMoreText="Continue Reading..."
            />
          </p>
        </div>
      </div>
    </section>
  );
};

export default UnveilingSatoshi;
