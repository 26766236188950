import HomeDemo3Container from '../template/HomeDemo3'
import Layout from "../layouts"
const HomeDemo3 = () => {

  return (
    <Layout Title="HomeDemo3">
      <HomeDemo3Container />
    </Layout>
  );
}

export default HomeDemo3;