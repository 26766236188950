const FindSatoshi = ({ data }) => {
  return (
    <section
      className="hero-section-home-leftt"
      style={{ maxWidth: "1980px", marginLeft: "auto", marginRight: "auto" }}
    >
      <div className="container my-container" style={{ maxWidth: "1100px" }}>
        <div className="row justify-content-center">
          <img src="/img/core-img/ordinalFindSatoshi.svg" />
        </div>
      </div>
    </section>
  );
};

export default FindSatoshi;
